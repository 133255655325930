// en, de
export default {
    de: {
        Lang: {
            Language: 'Sprache',
            de: 'Deutsch',
            en: 'English',
        },
        Page: {
            Home: 'Kinderschutz und Jugendschutz App',
            Portal: 'Eltern Portal',
            HowTo: 'So geht´s',
            Prices: 'Preise',
            Advisor: 'Ratgeber',
            AboutUs: 'Über uns',
            Help: 'Hilfe',
            Support: 'Support',
            Instructions: 'Anleitungen',
            InstructionsAndroid: 'Anleitungen Android',
            InstructionsWindows: 'Anleitungen Windows',
            InstructionsHuawei: 'Anleitungen Huawei',
            WinDownload: "Windows Download",
            FAQ: 'FAQ',
            Error404: 'Fehler 404',
            Imprint: "Impressum",
            Privacy: "Datenschutz",
            PrivacyApp: "Datenschutz App",
            TOS: "AGB",
            Press: "Presse",
            JobOffers: "Stellenangebote"
        },
        Button: {
            Register: "Registrieren",
            HeroCta: "Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            HeroCta2: "Kostenlos ausprobieren",
            NowSaveKid: "Jetzt Kind schützen",
            TestNow: "Jetzt testen",
            SubscribeNow: "Jetzt abonnieren",
            SendMessage: "Nachricht absenden",
            SendMessageSending: "Nachricht versenden",
            SendMessageSuccess: "Erfolgreich",
            SendMessageError: "Fehler",
            ReadMore: "Weiterlesen >>",
            PdfDownload: "PDF Download",
            Download: "Download",
            ClickHere: "Klicken Sie bitte hier",
            ToDashboard: "weiter zur Startseite",
            Accept: "Einverstanden"
        },
        Text: {
            GoPortalLoginWelcome: "Willkommen zurück, hier können Sie sich einloggen.",
            Step: "Schritt",
            CookieText: "Wir nutzen Cookies auf unserer Website, um diese Website und Ihre Erfahrung zu verbessern.",
        },
        MetaDescription: {
            Home: 'Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            HowTo: 'So einfach geht’s -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Prices: 'Unsere Preisliste -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            AboutUs: 'Über uns -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Help: 'Hilfe -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Support: 'Support -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Instructions: 'Anleitungen -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            InstructionsAndroid: 'Anleitungen Android -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            InstructionsWindows: 'Anleitungen Windows -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            WinDownload: "Windows Download -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            FAQ: 'FAQ -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Error404: 'Fehler 404 -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.',
            Imprint: "Impressum -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            Privacy: "Datenschutz -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            PrivacyApp: "Datenschutz App -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            TOS: "AGB -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            Press: "Presse -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen.",
            JobOffers: "Stellenangebote -- Kinder- und Jugendschutz App für Android und Windows. Lass Dein Kind im Netz nicht allein - jetzt gratis testen."
        },
        Home: require('@/i18n/translations/Home-de.json'),
        HowTo: require('@/i18n/translations/HowTo-de.json'),
        Prices: require('@/i18n/translations/Prices-de.json'),
        AboutUs: require('@/i18n/translations/AboutUs-de.json'),
        Help: require('@/i18n/translations/Help-de.json'),
        Instructions: require('@/i18n/translations/Instructions-de.json'),
        Press: require('@/i18n/translations/Press-de.json'),
        JobOffers: require('@/i18n/translations/JobOffers-de.json'),
        Privacy: require('@/i18n/translations/Privacy-de.json'),
        PrivacyApp: require('@/i18n/translations/PrivacyApp-de.json'),
        TOS: require('@/i18n/translations/TOS-de.json'),
        Imprint: require('@/i18n/translations/Imprint-de.json'),
        WinDownload: {
          Headline: "Schützen Sie Ihr Kind mit der Kidgonet Jugendschutz App für Windows",
          Text: "<ul><li>Auszeiten und Zeitlimits festlegenli </li> <li>Schutz vor nicht altersgerechten Seiten</li> <li>Geräteübergreifender Schutz</li> <li>Absoluter Datenschutz – Kidgonet respektiert die Privatsphäre Ihres Kindes ¹</li> </ul>",
          Requirements: "¹ Es werden keine Nutzungsprofile der Kinder erstellt, Kidgonet erhebt keinerlei Benutzerdaten! <br> Minimum Anforderungen:<br> Prozessor: 1,8 GHz oder höher (Dual-Core oder besser empfohlen)<br> Arbeitsspeicher: 2 GB; 4 GB oder höher empfohlen<br> Das aktuelle Programm ist in der Beta Phase."
        },
        Error404: {
          Headline: "Fehler 404: Seite nicht gefunden",
          Text: "Die von Ihnen aufgerufene Seite existiert leider nicht.",
        },
        Footer: {
            SocialMedia1: "Immer auf dem Laufenden bleiben:",
            SocialMedia2: "Folge uns auf Instagram und Facebook"
        },
        Month: {
            M0: "Januar",
            M1: "Februar",
            M2: "März",
            M3: "April",
            M4: "Mai",
            M5: "Juni",
            M6: "Juli",
            M7: "August",
            M8: "September",
            M9: "Oktober",
            M10: "November",
            M11: "Dezember"
        }
    },
    en: {
        Lang: {
            Language: 'Language',
            de: 'Deutsch',
            en: 'English',
        },
        Page: {
            Home: 'Child protection and youth protection app',
            Portal: 'Parents Portal',
            HowTo: 'How to',
            Prices: 'Prices',
            Advisor: 'Advisor',
            AboutUs: 'About us',
            Help: 'Help',
            Support: 'Support',
            Instructions: 'Instructions',
            InstructionsAndroid: 'Instructions Android',
            InstructionsWindows: 'Instructions Windows',
            InstructionsHuawei: 'Instructions Huawei',
            WinDownload: "Windows download",
            FAQ: 'FAQ',
            Error404: 'Error 404',
            Imprint: "Imprint",
            Privacy: "Privacy",
            PrivacyApp: "Privacy App",
            TOS: "TOS",
            Press: "Press",
            JobOffers: "Job Offers"
        },
        Button: {
            Register: "Register",
            HeroCta: "Don't leave your child alone on the net - free trial now.",
            HeroCta2: "Try for free",
            NowSaveKid: "Protect your child now",
            TestNow: "Test now",
            SubscribeNow: "Subscribe now",
            SendMessage: "Send message",
            SendMessageSending: "sending message",
            SendMessageSuccess: "Successful",
            SendMessageError: "Error",
            ReadMore: "read more >>",
            PdfDownload: "PDF Download",
            Download: "Download",
            ClickHere: "Please click here",
            ToDashboard: "to dashboard",
            Accept: "Accept"
        },
        Text: {
            GoPortalLoginWelcome: "Welcome back, you can log in here.",
            Step: "Step",
            CookieText: "We use cookies on our website to improve this website and your experience.",
        },
        MetaDescription: {
            Home: 'Child and youth protection app for Android and Windows. Don\'t leave your child alone on the net - try it now for free',
            HowTo: 'It\'s that easy -- Child and Youth Protection App for Android and Windows. Don\'t leave your child alone on the net - try it now for free.',
            Prices: 'Our price list -- Child and youth protection app for Android and Windows. Don\'t leave your child alone on the net - try it for free now',
            AboutUs: 'About us -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now',
            Help: 'Help -- Child and youth protection app for Android and Windows. Don\'t leave your child alone on the net - try it for free now',
            Support: 'Support -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it now for free.',
            Instructions: 'Instructions -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now',
            InstructionsAndroid: 'Instructions Android -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now.',
            InstructionsWindows: 'Instructions Windows -- Child and youth protection app for Android and Windows. Don\'t leave your child alone on the net - try it for free now',
            WinDownload: 'Windows Download -- Child and Youth Protection App for Android and Windows. Don\'t leave your child alone on the net - try it for free now.',
            FAQ: 'FAQ -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now',
            Error404: 'Error 404 -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - test it now for free',
            Imprint: 'Imprint -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now.',
            Privacy: 'Privacy -- Kinder- und Jugendschutz App für Android und Windows. Don\'t leave your child alone on the net - try it for free now.',
            PrivacyApp: "Datenschutz App -- Kinder- und Jugendschutz App für Android und Windows. Don't leave your child alone on the net - try it for free now.",
            TOS: "AGB -- Kinder- und Jugendschutz App für Android und Windows. Don't leave your child alone on the net - try it for free now.",
            Press: "Press -- Kinder- und Jugendschutz App für Android und Windows. Don't leave your child alone on the net - try it for free now.",
            JobOffers: "Jobs -- Kinder- und Jugendschutz App für Android und Windows. Don't leave your child alone on the net - try it for free now."
        },
        Home: require('@/i18n/translations/Home-en.json'),
        HowTo: require('@/i18n/translations/HowTo-en.json'),
        Prices: require('@/i18n/translations/Prices-en.json'),
        AboutUs: require('@/i18n/translations/AboutUs-en.json'),
        Help: require('@/i18n/translations/Help-en.json'),
        Instructions: require('@/i18n/translations/Instructions-en.json'),
        Press: require('@/i18n/translations/Press-en.json'),
        JobOffers: require('@/i18n/translations/JobOffers-en.json'),
        Privacy: require('@/i18n/translations/Privacy-en.json'),
        PrivacyApp: require('@/i18n/translations/PrivacyApp-en.json'),
        TOS: require('@/i18n/translations/TOS-en.json'),
        Imprint: require('@/i18n/translations/Imprint-en.json'),
        WinDownload: {
            Headline: "Protect your child with the Kidgonet Youth Protection app for Windows",
            Text:"<ul><li>Set timeouts and time limitsli </li> <li>Protect against sites that are not age-appropriate</li> <li>Cross-device protection</li> <li>Absolute privacy - Kidgonet respects your child's privacy¹</li> </ul>",
            Requirements:"¹ No usage profiles of children are created, Kidgonet does not collect any user data! <br> Minimum Requirements:<br> Processor: 1.8 GHz or higher (dual-core or better recommended)<br> Memory: 2 GB; 4 GB or higher recommended<br> The current program is in beta."
        },
        Error404: {
            Headline: "Error 404: Page not found",
            Text: "Sorry, the page you requested does not exist",
        },
        Footer: {
            SocialMedia1: "Always stay up to date:",
            SocialMedia2: "Follow us on Instagram and Facebook"
        },
        Month: {
            M0: "January",
            M1: "February",
            M2: "March",
            M3: "April",
            M4: "May",
            M5: "June",
            M6: "July",
            M7: "August",
            M8: "September",
            M9: "October",
            M10: "November",
            M11: "December"
        }
    },

}
