import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '@/i18n/i18n'
import cookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
    {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
        path: '/:locale?',
        component: () => import('./../layouts/Main.vue'),
        beforeEnter: (to, from, next) => {
            if (to.params.locale && to.params.locale.length !== 2) {
                // /privcay -> /de/privacy
                next({name: to.params.locale, params: {locale: cookies.get('lang')}})
            } else if (to.params.locale !== cookies.get('lang')) {
                // check if choosen language is available, if not fallback 'de'
                // change language if user manual switch in url
                const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
                let currentLang = ''
                if (supported_locales.includes(to.params.locale)) {
                    currentLang = to.params.locale
                } else {
                    currentLang = 'de'
                }

                const html = document.documentElement
                i18n.locale = currentLang
                html.setAttribute('lang', currentLang)
                cookies.set('lang', currentLang)
                next({name: to.name, params: {locale: currentLang}})

            } else {
                // handling for adding lang tag
                const locale = to.params.locale
                const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
                if (!supported_locales.includes(locale)) return next('de')
                if (i18n.locale !== locale) {
                    i18n.locale = locale
                }
                return next()
            }
        },

        children: [
            // =============================================================================
            // Theme Routes
            // =============================================================================
            {
                path: '/:locale?',
                name: 'dashboard',
                component: Home,
            },
            {
                path: 'how-to',
                name: 'how-to',
                component: () => import('../views/How-to.vue'),
            },
            {
                path: 'prices',
                name: 'prices',
                component: () => import('../views/Prices.vue'),
            },
            {
                path: 'about-us',
                name: 'about-us',
                component: () => import('./../views/About-us.vue'),
            },
            {
                path: 'help',
                name: 'help',
                component: () => import('./../views/Help-Faq.vue'),
            },
            {
                path: 'help/faq',
                name: 'help/faq',
                component: () => import('./../views/Help-Faq.vue'),
            },
            {
                path: 'help/faq/xiaomi',
                name: 'help/faq/xiaomi',
                component: () => import('./../views/Help-FAQ-Xiaomi.vue'),
            },
            {
                path: 'instructions',
                name: 'instructions',
                component: () => import('./../views/Instructions.vue'),
            },
            {
                path: 'instructions/android',
                name: 'instructions/android',
                component: () => import('./../views/Instructions-Android.vue'),
            },
            {
                path: 'instructions/ios',
                name: 'instructions/ios',
                component: () => import('./../views/Instructions-iOS.vue'),
            },
            {
                path: 'instructions/windows',
                name: 'instructions/windows',
                component: () => import('./../views/Instructions-Windows.vue'),
            },
            {
                path: 'instructions/install-huawei',
                name: 'instructions/install-huawei',
                component: () => import('./../views/Instructions-Huawei.vue'),
            },
            {
                path: 'instructions/explanation-vpn',
                name: 'instructions/explanation-vpn',
                component: () => import('./../views/Instructions-Explanation-vpn.vue'),
            },
            {
                path: 'instructions/install-huawei-ultra-saving',
                name: 'instructions/install-huawei-ultra-saving',
                component: () => import('./../views/Instructions-Huawei-Ultra-Saving.vue'),
            },
            {
                path: 'instructions/huawei',
                name: 'instructions/huawei',
                component: () => import('./../views/Instructions-Huawei.vue'),
            },
            {
                path: 'win-download',
                name: 'win-download',
                component: () => import('./../views/Download-Windows.vue'),
            },
            {
                path: 'imprint',
                name: 'imprint',
                component: () => import('./../views/Imprint.vue'),
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('./../views/Privacy.vue'),
            },
            {
                path: 'privacy-app',
                name: 'privacy-app',
                component: () => import('../views/Privacy-App.vue'),
            },
            {
                path: 'tos',
                name: 'tos',
                component: () => import('../views/TOS.vue'),
            },
            {
                path: 'job-offers',
                name: 'job-offers',
                component: () => import('./../views/Job-offers.vue'),
            },
            {
                path: 'press',
                name: 'press',
                component: () => import('./../views/Presse.vue'),
            },
            {
                path: 'press/pressetext_okt-2020',
                name: 'press/pressetext_okt-2020',
                component: () => import('./../views/Presse-Text.vue'),
            },
            {
                path: 'press/pressetext_jan_2023',
                name: 'press/pressetext_jan_2023',
                component: () => import('./../views/Presse-Text-Nokia.vue'),
            },
        ],
    },
    // Redirect to 404 page, if no match found
    {
        // path: "*",
        path: "/:locale/:catchAll(.*)",
        name: "NotFound",
        component: () => import('./../views/Error404.vue'),

        // redirect: { name: 'dashboard'}
    }
]

// workaround functions for transition elements
function wait(duration) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
    while (timeout > 0) {
        const el = document.querySelector(hash);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
            break;
        }
        await wait(delay);
        timeout = timeout - delay;
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // Required because our <RouterView> is wrapped in a <Transition>
            // So elements are mounted after a delay
            tryScrollToAnchor(to.hash, 1000, 100)
        } else if (savedPosition) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        savedPosition
                    });
                }, 200)
            })
        } else {
            return {x: 0, y: 0}
        }
    }
})


// subroute for redirect if | /help/faq -> de/help/faq
// atm no fix for general router
let subroutesHelper = [
    'help/faq',
    'help/faq/',
    'help/faq/xiaomi',
    'instructions/android',
    'instructions/ios',
    'instructions/windows',
    'instructions/install-huawei',
    'instructions/explanation-vpn',
    'instructions/install-huawei-ultra-saving',
    'instructions/huawei',
    'press/pressetext_okt-2020'
]

function redirectHelper(toName){
    if (cookies.isKey('lang')) {
        return({name: toName, params: {locale: cookies.get('lang')}})
    } else {
        return({name: toName, params: {locale: i18n.locale}})
    }
}

router.beforeEach((to, from, next) => {
    let l = router.resolve({name: to.name})
    if (l.resolved.matched.length > 0) {
        if (to.params.catchAll === '') {
            // if route is / redirect to /:lang
            next(redirectHelper('dashboard'))
        } else {
            // subroute redirect for /help/faq -> de/help/faq
            // atm no fix for general router

            if (subroutesHelper.includes(to.params.catchAll)) {
                let checkLastChar = to.params.catchAll.substring(to.params.catchAll.length - 1, to.params.catchAll.length) === '/'
                if(checkLastChar){
                    // case: help/faq/ -> de/help/faq
                    next(redirectHelper(to.params.catchAll.substring(0, to.params.catchAll.length - 1)))
                } else {
                    next(redirectHelper(to.params.catchAll))
                }
            }
            next()
        }

    } else {
        //the route does not exists, push to dashboard
        next(redirectHelper('dashboard'))
    }
})

export default router
