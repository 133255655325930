<template>
  <footer class="pt-3">
    <b-row class="align-items-center">

      <b-col cols="12">

        <div class="social-media-area">
          <div class="social-media-text">
            <span>{{ $t('Footer.SocialMedia1') }}</span>
            <span>{{ $t('Footer.SocialMedia2') }}</span>
          </div>
          <div class="social-media-logos">
            <a href="https://www.instagram.com/kidgonet/" target="_blank" rel="noopener">
              <img src="@/assets/images/instagram-logo.webp" alt="Kidgonet Instagram Page" width="70" height="70">
            </a>
            <a href="https://www.facebook.com/Kidgonet" target="_blank" rel="noopener">
              <img src="@/assets/images/facebook-logo.webp" alt="Kidgonet Facebook Page" width="70" height="70">
            </a>
          </div>
        </div>

      </b-col>

      <b-col cols="12" class="pt-5 mt-5 pt-md-3">
        <b-row>
          <b-col cols="12" sm="5">
            <p>Copyright Kidgonet GmbH - All Rights Reserved</p>
          </b-col>

          <b-col cols="12" sm="7">
            <div class="footer-nav float-right">
              <router-link :to="{ name: 'imprint', params: { locale: this.$i18n.locale }}">{{ $t('Page.Imprint') }}</router-link>
              <router-link :to="{ name: 'privacy', params: { locale: this.$i18n.locale }}">{{ $t('Page.Privacy') }}</router-link>
              <router-link :to="{ name: 'privacy-app', params: { locale: this.$i18n.locale }}">{{ $t('Page.PrivacyApp') }}</router-link>
              <router-link :to="{ name: 'tos', params: { locale: this.$i18n.locale }}">{{ $t('Page.TOS') }}</router-link>
              <router-link :to="{ name: 'press', params: { locale: this.$i18n.locale }}">{{ $t('Page.Press') }}</router-link>
              <router-link :to="{ name: 'job-offers', params: { locale: this.$i18n.locale }}">{{ $t('Page.JobOffers') }}</router-link>
            </div>

          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="my-5" v-if="size <= 576">
        <!-- spacer for smartphones -->
        <p class="color-white">spacer</p>
      </b-col>

    </b-row>
  </footer>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      size: this.$store.state.windowWidth,
    }
  },
  watch: {
    '$store.state.windowWidth'(val) {
      this.size = val
    },
  },
}
</script>