<template>
  <b-container id="app" class="px-4">
    <b-row v-if="isDev" class="justify-content-center">
      <div class="full-width-bar bg-danger color-white py-3"><strong>DEV Umgebung</strong></div>
    </b-row>
    <b-row>
      <b-col cols="12">
        <Header></Header>
        <router-view/>
        <Footer></Footer>
      </b-col>
    </b-row>
  </b-container>
</template>

    }
<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import '@/components/cookieconsent-ini'

export default {
  components: {
    Header,
    Footer
  },
  metaInfo() {
    return {
      title: 'Kidgonet App',
      titleTemplate: '%s | ' + this.$t('Page.Home')
    }
  },
  data() {
    return {
      isDev: this.checkforDev()
    }
  },
  methods: {
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    },
    checkforDev() {
      let state = false
      if (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('web.kidgonet.dev') !== -1) {
        state = true
      }
      return state
    },

  },
  mounted() {

    // check for cookies and add matomo
    let getCCCookie = this.$cookies.get('cc_cookie')
    if(getCCCookie && getCCCookie.categories.includes('analytics')) {
      if (this.$matomo) {
        this.$matomo.rememberConsentGiven()
      }
    }

    if (this.$cookies.isKey('lang')) {
      this.$i18n.locale = this.$cookies.get('lang')
    }
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

  },
  async created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  watch: {
    '$store.state.cookieNotice'(val) {
      if (val === 1 && this.$matomo) {
        this.$matomo.rememberConsentGiven()
      }
    },
  }
}
</script>
