
import 'vanilla-cookieconsent/dist/cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import cookies from "vue-cookies"
import store from '../store/index'

function addHelpDeskScript() {
    let shouldAppend = false

    let src = 'https://euc-widget.freshworks.com/widgets/103000003077.js'
    let el = document.querySelector('script[src="' + src + '"]')
    if (!el) {
        el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        el.setAttribute('id', 'helpdeskSC')
        shouldAppend = true
    }

    if (shouldAppend) {
        document.head.appendChild(el)
        implementWidgetForm()
    }
}

function implementWidgetForm(){
    window.fwSettings={
        'widget_id':103000003077,
        'locale': 'en'
    }
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
}

let getLang = 'de';

if(cookies.get('lang')){
    getLang = cookies.get('lang')
}


// obtain plugin
var cc = window.initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: getLang,
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    auto_language: null,                     // default: null; could also be 'browser' or 'document'
    force_consent: true,                   // default: false

    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },

    // onFirstAction: function(user_preferences, cookie){
       // console.log('onFirstAction: ', user_preferences, cookie)
    //},

    onAccept: function (cookie) {
        if(cookie.categories.includes('analytics')){
            store.commit('UPDATE_COOKIE_NOTICE', 1)
            addHelpDeskScript()
        }
    },

    //onChange: function (cookie, changed_preferences) {
      //  console.log('onChange: ', cookie, changed_preferences)
    //},

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Configure',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/de/privacy" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^matomo',       // match all cookies starting with "_ga"
                                col2: 'cdn.kidgonet.de',
                                col3: 'SESSION',
                                col4: 'Matomo Analytics',
                                is_regex: true
                            },
                            {
                                col1: '^euc-widget',       // match all cookies starting with "_ga"
                                col2: 'euc-widget.freshworks.com/widgets/103000003077.js',
                                col3: 'SESSION',
                                col4: 'Freshworks Helpdesk',
                                is_regex: true
                            }
                        ]
                    }
                ]
            }
        },
        'de': {
            consent_modal: {
                title: 'Wir benutzen Cookies!',
                description: 'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt werden.',
                primary_btn: {
                    text: 'Alle akzeptieren',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Anpassen',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie Einstellungen',
                save_settings_btn: 'Auswahl speichern',
                accept_all_btn: 'Alle akzeptieren',
                close_btn_label: 'Schließen',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Ablauf'},
                    {col4: 'Beschreibung'}
                ],
                blocks: [
                    {
                        title: 'Cookie Verwendung 📢',
                        description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/de/privacy" class="cc-link">Datenschutzerklärung</a>.'
                    }, {
                        title: 'Streng notwendige Cookies',
                        description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: 'Performance und Analytics Cookies',
                        description: 'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^matomo',       // match all cookies starting with "_ga"
                                col2: 'cdn.kidgonet.de',
                                col3: 'SESSION',
                                col4: 'Matomo Analytics',
                                is_regex: true
                            },
                            {
                                col1: '^euc-widget',       // match all cookies starting with "_ga"
                                col2: 'euc-widget.freshworks.com/widgets/103000003077.js',
                                col3: 'SESSION',
                                col4: 'Freshworks Helpdesk',
                                is_regex: true
                            }
                        ]
                    }
                ]
            }
        }
    }
});
