<template>

    <b-container class="py-4 mx-0">

        <b-row class="align-items-center">
            <b-col cols="6" sm="6" md="3" class="">
                <router-link :to="{ name: 'dashboard', params: { locale: this.$i18n.locale }}">
<!--                    <img class="logo" alt="Kidgonet Startseite" src="@/assets/images/kgn-logo.webp" width="224">-->

                    <img v-if="(currentLang === 'de' || currentLang !== 'en')" class="logo" alt="Kidgonet Startseite" src="@/assets/images/kgn-logo.webp" width="224">
                    <img v-if="currentLang === 'en'" class="logo" alt="Kidgonet Startseite" src="@/assets/images/kgn_logo_en.png" width="224">
                </router-link>
            </b-col>
            <b-col cols="6" sm="6" md="9">
                <div id="navigation" class="float-right">

                    <div id="nav" v-if="size > 1024">
                        <a href="https://portal.kidgonet.de/" target="_blank" rel="noopener">{{ $t('Page.Portal') }}</a>

                        <router-link :to="{ name: 'how-to', params: { locale: this.$i18n.locale }}">{{$t('Page.HowTo')}}
                        </router-link>
                        <router-link :to="{ name: 'prices', params: { locale: this.$i18n.locale }}">{{$t('Page.Prices')}}
                        </router-link>
                        <router-link :to="{ name: 'about-us', params: { locale: this.$i18n.locale }}">
                            {{ $t('Page.AboutUs') }}
                        </router-link>

                        <div class="dropdown">
                            <router-link :to="{ name: 'help/faq', params: { locale: this.$i18n.locale }}">{{$t('Page.Help')}}
                            </router-link>
                            <div class="dropdown-content">
<!--                                <div>-->
<!--                                    <router-link :to="{ name: 'help', params: { locale: this.$i18n.locale }}">-->
<!--                                        {{ $t('Page.Support') }}-->
<!--                                    </router-link>-->
<!--                                </div>-->
                                <div>
                                    <router-link :to="{ name: 'instructions', params: { locale: this.$i18n.locale }}">
                                        {{ $t('Page.Instructions') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'help/faq', params: { locale: this.$i18n.locale }}">
                                        {{ $t('Page.FAQ') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <b-dropdown id="header-switch" class="lang-switch m-md-2">
                            <template #button-content>
                                <img :src="langImgLink" alt="" width="20" height="20" class="mr-1 lang-flag"> {{ $t('Lang.'+$i18n.locale) }}
                            </template>

                            <b-dropdown-item @click="changeLang($event)" data-lang="de">
                                <img src="@/assets/images/flags/de.png" alt="" width="20" height="20" class="mr-1 lang-flag">{{ $t('Lang.de') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="changeLang($event)" data-lang="en">
                                <img src="@/assets/images/flags/en.png" alt="" width="20" height="20" class="mr-1 lang-flag">{{ $t('Lang.en') }}
                            </b-dropdown-item>
                        </b-dropdown>

                    </div>

                    <!-- mobile nav -->
                    <Slide :closeOnNavigation="true" right noOverlay v-if="size <= 1024">
                        <a href="https://portal.kidgonet.de/" target="_blank" rel="noopener">{{ $t('Page.Portal') }}</a>
                        <router-link :to="{ name: 'how-to', params: { locale: this.$i18n.locale }}">{{$t('Page.HowTo')}}
                        </router-link>
                        <router-link :to="{ name: 'prices', params: { locale: this.$i18n.locale }}">{{$t('Page.Prices')}}
                        </router-link>
                        <router-link :to="{ name: 'about-us', params: { locale: this.$i18n.locale }}">{{ $t('Page.AboutUs') }}
                        </router-link>
<!--                        <router-link :to="{ name: 'help', params: { locale: this.$i18n.locale }}">{{$t('Page.Support')}}-->
<!--                        </router-link>-->
                        <router-link :to="{ name: 'instructions', params: { locale: this.$i18n.locale }}">{{ $t('Page.Instructions') }}
                        </router-link>
                        <router-link :to="{ name: 'help/faq', params: { locale: this.$i18n.locale }}">{{$t('Page.FAQ')}}
                        </router-link>
                        <b-dropdown :text="$t('Lang.Language')" id="header-switch" class="lang-switch m-md-2 ml-0">
                            <template #button-content>
                                <img :src="langImgLink" alt="" width="20" height="20" class="mr-1 lang-flag"> {{ $t('Lang.'+$i18n.locale) }}
                            </template>
                            <b-dropdown-item @click="changeLang($event)" data-lang="de"><img
                                src="@/assets/images/flags/de.png" alt="" width="20" height="20"
                                class="mr-1">{{ $t('Lang.de') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="changeLang($event)" data-lang="en"><img
                                src="@/assets/images/flags/en.png" alt="" width="20" height="20"
                                class="mr-1">{{ $t('Lang.en') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </Slide>

                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
// hamburger menu
import {Slide} from 'vue-burger-menu'

export default {
    components: {
        Slide
    },
    data() {
        return {
            size: this.$store.state.windowWidth,
            langImgLink: this.getImgUrl(),
            currentLang: this.$i18n.locale
        }
    },
    mounted() {

    },
    methods: {
        changeLang(item) {
            const html = document.documentElement

            if (this.$i18n.locale !== item.target.getAttribute('data-lang')) {
                const currentLang = item.target.getAttribute('data-lang')

                this.$i18n.locale = currentLang
                html.setAttribute('lang', currentLang)
                this.$cookies.set('lang', currentLang)
                this.currentLang = currentLang

                const to = this.$router.resolve({params: {locale: currentLang}})
                this.$router.push(to.location)
            }
        },
        getImgUrl() {
            return require('../assets/images/flags/'+this.$i18n.locale+'.png')
        }
    },
    watch: {
        '$store.state.windowWidth'(val) {
            this.size = val
        },
        '$i18n.locale'(){
            this.langImgLink = this.getImgUrl()
        }
    },
}
</script>
