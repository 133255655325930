import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowWidth: window.innerWidth,
    cookieNotice: 2
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth = width },
    UPDATE_COOKIE_NOTICE(state, val)  { state.cookieNotice = val },
  },
  actions: {
  },
  modules: {
  }
})
