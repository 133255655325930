<template>


  <b-container>

    <!-- LP box -->
    <div class="lp-box d-flex p-2" v-if="size > 991">
      <div><img src="@/assets/images/bundle-img.png" alt="" height="120" class="p-2"> </div>
      <div class="d-flex align-items-center">
        <div>
          <span class="color-primary font-weight-bold">{{$t('Home.LPTextNew')}}:</span><br>
          <span>{{$t('Home.LPText1')}}</span><br>
          <span>{{$t('Home.LPText2')}}</span> <br>
          <span>
            <a class="font-weight-bold color-bodyColor" href="https://www.prokids-phone.com/" target="_blank">{{$t('Home.LPLinkText')}}</a>
          </span>
        </div>
      </div>
    </div>

    <!--  hero area  -->
    <b-row :class="{'hero-area': size <= 991, 'hero-area d-flex align-content-end': size > 991}">
      <!-- desktop content and hero images for tablet and smaller -->
      <b-col cols="12" class="pb-5 pl-0" v-if="size > 991">
        <b-row class="pb-5 ">
          <b-col cols="6" class="">
            <div class="hero-content">
              <div class="pb-4">
                <span class="hero-headline"><strong>{{ $t('Home.HeroHeadline') }}</strong></span> <br>
                <span class="hero-subline" v-html="$t('Home.HeroSubline2')"></span><br>
                <span class="hero-sub-subline" v-html="$t('Home.HeroSubline3')"></span>
              </div>

              <div class="pb-4">
                <a href="https://portal.kidgonet.de/#!/register" target="_blank" rel="noopener">
                  <b-button class="btn-kgn-primary color-bodyColor">{{ $t('Button.HeroCta2') }}</b-button>
                </a>
              </div>

              <div>
                <a href="https://play.google.com/store/apps/details?id=de.kidgonet.portal" target="_blank"
                   rel="noopener">
                  <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/google-play-badge.webp" width="202" height="62"
                       :alt="$t('Home.HeroLink3Alt')">
                  <img v-if="currentLang === 'en'" src="@/assets/images/google-play-badge-en.png" width="202" height="auto" :alt="$t('Home.HeroLink3Alt')">
                </a>
              </div>

            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!--  hero area for tablet and smaller  -->
    <b-row v-if="size <= 991" class="py-4">
      <b-col cols="12">
        <div class="hero-content">
          <div class="pb-4">
            <span class="hero-headline"><strong>{{ $t('Home.HeroHeadline') }}</strong></span> <br>
            <span class="hero-subline" v-html="$t('Home.HeroSubline2')"></span> <br>
            <span class="hero-sub-subline" v-html="$t('Home.HeroSubline3')"></span>
          </div>

          <div class="pb-4">
            <a href="https://portal.kidgonet.de/#!/register" target="_blank" rel="noopener">
              <b-button class="btn-kgn-primary color-bodyColor">{{ $t('Button.HeroCta2') }}</b-button>
            </a>
          </div>

          <div :class="{'d-flex justify-content-center': size <= 768}">
            <a href="https://play.google.com/store/apps/details?id=de.kidgonet.portal"
               target="_blank" rel="noopener">
              <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/google-play-badge.webp" width="202" height="62"
                   :alt="$t('Home.HeroLink3Alt')">
              <img v-if="currentLang === 'en'" src="@/assets/images/google-play-badge-en.png" width="202" height="auto" :alt="$t('Home.HeroLink3Alt')">
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="size <= 991" class="full-width-bar py-4">
      <b-col cols="12" class="d-flex align-items-center pl-0 pr-0">
        <div><img src="@/assets/images/bundle-img.png" alt="" height="200" class="hero-fit"> </div>

        <div class="pl-0 pr-0 pt-4 pb-4">
          <div class="d-flex align-items-center pl-4">
            <div class="lp-box-mobile">
              <span class="color-primary font-weight-bold">{{$t('Home.LPTextNew')}}:</span><br>
              <span>{{$t('Home.LPText1')}}</span><br>
              <span>{{$t('Home.LPText2')}}</span> <br>
              <span>
                <a class="font-weight-bold color-bodyColor" href="https://www.prokids-phone.com/" target="_blank">{{$t('Home.LPLinkText')}}</a>
              </span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!--  hero area END  -->



    <!--  app show area  -->
    <b-row class="py-5 app-show">

      <b-col cols="12" sm="4">
        <b-row class="text-center">
          <b-col cols="12">
            <img src="@/assets/images/Kidgonet-Jugendschutz-App.svg" alt="" width="106" height="106">
          </b-col>
          <b-col cols="12" class="font22 pt-3">
            <p><strong class="d-block f-w-700">{{ $t('Home.AppShowHeadline1') }}</strong>
              {{ $t('Home.AppShowSubline1') }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" sm="4">
        <b-row class="text-center">
          <b-col cols="12">
            <img src="@/assets/images/kidgonet-portal.png" alt="" width="106" height="106">
          </b-col>
          <b-col cols="12" class="font22 pt-3">
            <p>
              <strong class="d-block f-w-700">{{ $t('Home.AppShowHeadline2') }}</strong>
              {{ $t('Home.AppShowSubline2') }}
              <a href="https://portal.kidgonet.de/#!/login" class="d-block" target="_blank"
                 rel="noopener">{{ $t('Home.AppShowSubline2LinkText') }}</a>
            </p>

          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" sm="4">
        <b-row class="text-center">
          <b-col cols="12">
            <img src="@/assets/images/go-online-fuer-windows.webp" alt="" width="106" height="106">
          </b-col>
          <b-col cols="12" class="font22 pt-3">
            <p><strong class="d-block f-w-700">{{ $t('Home.AppShowHeadline3') }}</strong>
              {{ $t('Home.AppShowSubline3') }}
              <router-link :to="{ name: 'win-download', params: { locale: this.$i18n.locale }}"
                           class="d-block">{{ $t('Home.AppShowSubline3LinkText') }}
              </router-link>
            </p>
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <!--  smartphone slider area  -->
    <b-row class="full-width-bar">
      <b-col cols="12">

        <b-row class="py-5" :class="{'align-items-center': size > 768}">

          <b-col cols="12" sm="6" md="4">
            <b-row>
              <b-col cols="12" class="pb-3">
                <div class="pb-3 font25">{{ $t('Home.PSliderAreaHeadline1') }}</div>
                <div v-html="$t('Home.PSliderAreaText1')"></div>
              </b-col>
              <b-col cols="12">
                <div class="pb-3 font25">{{ $t('Home.PSliderAreaHeadline2') }}</div>
                <div>{{ $t('Home.PSliderAreaText2') }}</div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" sm="6" md="4" v-if="size >= 768">
            <b-row>
              <b-col cols="12" class="font22 pt-3">

                <div class="px-2">
                  <agile :autoplay="true" :nav-buttons="false" :dots="false" :center-mode="true" :autoplaySpeed="3000">
                    <div class="slide">
                      <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/slider/01_bildschirmzeit_de.png" alt="">
                      <img v-if="currentLang === 'en'" src="@/assets/images/slider/01_bildschirmzeit_en.png" alt="">
                    </div>
                    <div class="slide">
                      <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/slider/02_pause_de.png" alt="">
                      <img v-if="currentLang === 'en'" src="@/assets/images/slider/02_pause_en.png" alt="">
                    </div>
                    <div class="slide">
                      <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/slider/03_blockseite_de.png" alt="">
                      <img v-if="currentLang === 'en'" src="@/assets/images/slider/03_blockseite_en.png" alt="">
                    </div>
                  </agile>
                </div>

              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" sm="6" md="4" :class="{'pt-3': size < 576}">
            <b-row>
              <b-col cols="12">
                <div class="pb-3 font25">{{ $t('Home.PSliderAreaHeadline3') }}</div>
                <div>{{ $t('Home.PSliderAreaText3') }}</div>
              </b-col>
              <b-col cols="12" class="font22 pt-3 box-sizing hero-content">
                <div :class="{'text-center': size < 576}">
                  <img v-if="(currentLang === 'de' || currentLang !== 'en')"
                       src="@/assets/images/geraeteuebergreifend_2_de.png" width="auto" height="234" alt="" class="pb-4">
                  <img v-if="currentLang === 'en'" src="@/assets/images/geraeteuebergreifend_2_en.png" width="297"
                       height="auto" alt="" class="pb-4">
                </div>
                <a href="https://portal.kidgonet.de/#!/register" target="_blank" rel="noopener">
                  <div class="cta-arrow cta-small-hero mb-4">
                    <img src="@/assets/images/cta-check-arrow.png" alt="">
                    <p class="font20">{{ $t('Button.HeroCta') }}</p>
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-col>


        </b-row>
      </b-col>
    </b-row>

    <!--   kid for kid area  -->
    <b-row cols="12" class="py-5">
      <b-col cols="12" sm="3" v-if="size > 991">
        <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/dt-1.webp" alt="">
        <img v-if="currentLang === 'en'" src="@/assets/images/dt-1_en.png" alt="">
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="6">
        <b-row class="align-content-start" :class="{'align-items-center': size < 992}">
          <b-col cols="12" sm="8">
            <h2 class="text-center">{{ $t('Home.KFKAreaHeadline') }}</h2>
          </b-col>
          <b-col cols="12" sm="4" v-if="size > 575">
            <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/mit-kindern-fuer-kinder-entwickelt.webp" alt="">
            <img v-if="currentLang === 'en'" src="@/assets/images/mit-kindern-fuer-kinder-entwickelt_en.png" alt="">
          </b-col>

          <b-col cols="12" class="text-center font20 pt-4">
            <p v-html="$t('Home.KFKAreaText')"></p>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" sm="3" v-if="size > 991">
        <img v-if="(currentLang === 'de' || currentLang !== 'en')" src="@/assets/images/dt-2.webp" alt="">
        <img v-if="currentLang === 'en'" src="@/assets/images/dt-2_en.png" alt="">
      </b-col>

    </b-row>

    <!-- pros area -->
    <b-row class="full-width-bar pt-5">
      <b-col cols="12" sm="12" md="8" lg="4" :class="{'pb-5': size < 992}">
        <img v-if="(currentLang === 'de' || currentLang !== 'en')" class="pros-area-img"
             src="@/assets/images/protected_de.png" alt="">
        <img v-if="currentLang === 'en'" class="pros-area-img" src="@/assets/images/protected_en.png" alt="">
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4">

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea1') }}</div>
        </div>

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea2') }}</div>
        </div>

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea3') }}</div>
        </div>

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea4') }}</div>
        </div>

      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4">

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea5') }}</div>
        </div>

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea6') }}</div>
        </div>

        <div class="pb-3">
                    <span class="d-table-cell"><b-icon icon="check-square-fill" class="color-success mr-2"
                                                       font-scale="1.5"></b-icon></span>
          <div class="d-table-cell font-weight-600 pl-2">{{ $t('Home.ProArea7') }}</div>
        </div>

        <div class="pb-3 pt-2">
          <b-button href="https://portal.kidgonet.de/#!/register" target="_blank" rel="noopener"
                    class="btn-kgn-cta">{{ $t('Button.NowSaveKid') }}
          </b-button>
        </div>

      </b-col>
    </b-row>

  </b-container>


</template>

<script>

import {VueAgile} from 'vue-agile'

export default {
  components: {
    agile: VueAgile
  },
  metaInfo() {
    return {
      title: this.$t('Home.HeroHeadline'),
      meta: [
        {name: 'description', content: this.$t('MetaDescription.Home')}
      ],
    }
  },
  data() {
    return {
      portalUrl: "https://portal.kidgonet.de/#!/login",
      size: this.$store.state.windowWidth,
      currentLang: this.$i18n.locale
    }
  },
  methods: {},
  created() {

    var data = {
      "@context": "https://schema.org", "@graph":
          [
            {
              "@type": "LocalBusiness",
              "name": "Kidgonet GmbH",
              "image": "https://www.kidgonet.de/img/kgn-logo.741c0402.webp",
              "@id": "",
              "url": "https://www.kidgonet.de",
              "telephone": "+49 (89) 122 239 380",
              "priceRange": "3.49",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Eugen-Sänger-Ring 13",
                "addressLocality": "Brunnthal",
                "postalCode": "85649",
                "addressCountry": "DE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 48.03926344805018,
                "longitude": 11.663832376406738
              }
            },
            {
              "@type": "Organization",
              "name": "Kidgonet GmbH",
              "url": "https://www.kidgonet.de",
              "logo": "https://www.kidgonet.de/img/kgn-logo.741c0402.webp",
              "sameAs": [
                "https://www.instagram.com/kidgonet/",
                "https://www.facebook.com/Kidgonet"
              ]
            },
            {
              "@type": "Product",
              "name": "Kidgonet Jugendschutz App",
              "image": "https://www.kidgonet.de/img/Go-Online-Jugendschutz-App.5a866ceb.webp",
              "description": "Kinder- und Jugendschutz App für Android und Windows",
              "brand": {
                "@type": "Brand",
                "name": "Kidgonet GmbH"
              },
              "offers": {
                "@type": "AggregateOffer",
                "url": "https://www.kidgonet.de/de/prices",
                "priceCurrency": "EUR",
                "lowPrice": "3.49"
              }
            }
          ]
    }

    var script = document.createElement('script')
    script.type = "application/ld+json"
    script.id = "schema"
    script.innerHTML = JSON.stringify(data)
    document.head.appendChild(script)
  },
  destroyed() {
    document.getElementById('schema').remove()
  },
  watch: {
    '$store.state.windowWidth'(val) {
      this.size = val
    },
    '$i18n.locale'(val) {
      this.currentLang = val
    }
  },
}
</script>